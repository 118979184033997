import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"


const AboutPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="About TCB Technology" />

      <div class="title-image">

        <StaticImage 
          src="../images/tcbarrett-profile-image.png" 
          alt="TCBarrett Profile Image"
          placeholder="blurred"
          layout="fixed"
          width={200}
          height={200}
        />
      </div>

      <h1>About</h1>

      <p>
        I was brought up in a world where the internet was yet to be invented. 
        From the age of 8 I had hacked my first game and modified it so I would have infinite lives.
      </p>

      <p>I have always seen computers and software as a way to enrich and better our lives.</p>

      <p>
        Along came the internet. 
        I dabbled with HTML and CSS in the 90s whilst studying Computer Software Technology at university. 
        My first ever 'proper' job was converting magazines created with Quarkxpress into web pages. 
        From small titles about crocheting to international gaming magazines.
      </p>
        
      <p>
        From those small steps, a quirky place full of bulletin boards and text based multiplayer worlds, we have had an explosion of technology and software. 
      </p>

      <p>
        Everywhere you look. If you want to build a business, you will need technology and you will need the internet.
      </p>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`